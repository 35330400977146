<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header
        header-title="Create Incoming Phone Call Conversation"
      />

      <form class="row" :action="actionURL" method="post" @submit="setIsSubmitting()">
        <div class="medium-12 columns">
          <label :class="{ error: $v.phoneNumber.$error }">
            Phone number
            <input
              v-model.trim="phoneNumber"
              type="text"
              name="phone"
              placeholder="555-555-0111"
              @input="$v.phoneNumber.$touch"
            />
          </label>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="
                  $v.phoneNumber.$invalid ||
                  isSubmitting
              "
              button-text="Create"
              :loading="isSubmitting"
            />
            <button class="button clear" @click.prevent="onClose">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';

export default {
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      phoneNumber: '',
      vertical: 'bottom',
      horizontal: 'center',
      show: true,
      isSubmitting: false,
    };
  },
  validations: {
    phoneNumber: {
      required,
    },
  },
  methods: {
    setIsSubmitting() {
      this.isSubmitting = true;
    },
  },
  computed: {
    actionURL() {
      const accountId = window.location.pathname.split('/')[3];
      return `/rinsed/accounts/${accountId}/incoming_calls`;
    },
  },
};
</script>
