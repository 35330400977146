<template>
  <div
    class="h-full w-16 bg-white dark:bg-slate-900 border-r border-slate-50 dark:border-slate-800/50 rtl:border-l rtl:border-r-0 flex justify-between flex-col"
  >
    <div class="flex flex-col items-center">
      <logo
        :source="logoSource"
        :name="installationName"
        :account-id="accountId"
        class="m-4 mb-10"
      />
      <primary-nav-item
        v-for="menuItem in menuItems"
        :key="menuItem.toState"
        :icon="menuItem.icon"
        :name="menuItem.label"
        :to="menuItem.toState"
        :is-child-menu-active="menuItem.key === activeMenuItem"
      />

      <!-- RINSED -->
      <a
        v-if="isTenantChat"
        v-tooltip.right="'Incoming call'"
        class="button clear button--only-icon menu-item"
        style="color: #446888"
        @click="openIncomingPopup()"
      >
        <fluent-icon icon="incoming-call-rinsed" />
        <span class="sr-only">Incoming phone call</span>
      </a>

      <a
        v-if="isTenantChat"
        v-tooltip.right="'Start email conversation'"
        class="button clear button--only-icon menu-item"
        style="color: #446888"
        @click="openEmailPopup()"
      >
        <fluent-icon icon="manual-email-rinsed" />
        <span class="sr-only">Start email conversation</span>
      </a>
      <!-- RINSED END -->
    </div>
    <div class="flex flex-col items-center justify-end pb-6">
      <primary-nav-item
        v-if="!isACustomBrandedInstance"
        icon="book-open-globe"
        name="DOCS"
        :open-in-new-page="true"
        :to="helpDocsURL"
      />
      <notification-bell @open-notification-panel="openNotificationPanel" />
      <agent-details @toggle-menu="toggleOptions" />
      <options-menu
        :show="showOptionsMenu"
        @toggle-accounts="toggleAccountModal"
        @show-support-chat-window="toggleSupportChatWindow"
        @key-shortcut-modal="$emit('key-shortcut-modal')"
        @close="toggleOptions"
      />
    </div>

    <!-- RINSED -->
    <woot-modal :show.sync="showIncomingPopup" :on-close="hideIncomingPopup">
      <input-incoming-phone :on-close="hideIncomingPopup" />
    </woot-modal>
    <woot-modal :show.sync="showEmailPopup" :on-close="hideEmailPopup">
      <input-manual-email :on-close="hideEmailPopup" />
    </woot-modal>
    <!-- RINSED END -->
  </div>
</template>
<script>
// RINSED
import InputIncomingPhone from '../../../routes/dashboard/settings/agents/InputIncomingPhone.vue';
import InputManualEmail from '../../../routes/dashboard/settings/agents/InputManualEmail.vue';
// RINSED END

import Logo from './Logo.vue';
import PrimaryNavItem from './PrimaryNavItem.vue';
import OptionsMenu from './OptionsMenu.vue';
import AgentDetails from './AgentDetails.vue';
import NotificationBell from './NotificationBell.vue';
import wootConstants from 'dashboard/constants/globals';
import { frontendURL } from 'dashboard/helper/URLHelper';
import { ACCOUNT_EVENTS } from '../../../helper/AnalyticsHelper/events';

export default {
  components: {
    InputIncomingPhone, // RINSED
    InputManualEmail, // RINSED
    Logo,
    PrimaryNavItem,
    OptionsMenu,
    AgentDetails,
    NotificationBell,
  },
  props: {
    isACustomBrandedInstance: {
      type: Boolean,
      default: false,
    },
    logoSource: {
      type: String,
      default: '',
    },
    installationName: {
      type: String,
      default: '',
    },
    accountId: {
      type: Number,
      default: 0,
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    activeMenuItem: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      helpDocsURL: wootConstants.DOCS_URL,
      showOptionsMenu: false,
      // RINSED
      showIncomingPopup: false,
      showEmailPopup: false,
      isTenantChat: /^inbox\.rinsed\./.test(window.location.hostname),
      // RINSED END
    };
  },
  methods: {
    // RINSED
    openIncomingPopup() {
      this.showIncomingPopup = true;
    },
    hideIncomingPopup() {
      this.showIncomingPopup = false;
    },
    openEmailPopup() {
      this.showEmailPopup = true;
    },
    hideEmailPopup() {
      this.showEmailPopup = false;
    },
    // RINSED END

    frontendURL,
    toggleOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleAccountModal() {
      this.$emit('toggle-accounts');
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
    openNotificationPanel() {
      this.$track(ACCOUNT_EVENTS.OPENED_NOTIFICATIONS);
      this.$emit('open-notification-panel');
    },
  },
};
</script>
